import '@fontsource-variable/open-sans';
import '@fontsource-variable/spline-sans';
import './bootstrap';
import 'react-toastify/dist/ReactToastify.css';
import '../css/app.css';

import React from 'react';

import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faAlarmClock,
  faArrowRightFromBracket,
  faBars,
  faBell,
  faBookOpen,
  faBuildingColumns,
  faChartColumn,
  faCheck,
  faChevronDown,
  faChevronLeft,
  faChevronRight,
  faChevronUp,
  faCircleCheck,
  faCog,
  faCopy,
  faCreditCard,
  faDollarSign,
  faDownload,
  faEllipsis,
  faEllipsisVertical,
  faExclamationCircle,
  faFileInvoice,
  faFileInvoiceDollar,
  faGear,
  faMagnifyingGlass,
  faPaperPlane,
  faPlus,
  faSpinner,
  faStore,
  faTriangleExclamation,
  faUser,
  faUsers,
  faXmark,
} from '@fortawesome/pro-regular-svg-icons';
import { createInertiaApp } from '@inertiajs/react';
import * as Sentry from '@sentry/react';
import { resolvePageComponent } from 'laravel-vite-plugin/inertia-helpers';
import { createRoot } from 'react-dom/client';
import { ToastContainer } from 'react-toastify';

library.add(
  faArrowRightFromBracket,
  faAlarmClock,
  faBars,
  faBell,
  faBookOpen,
  faBuildingColumns,
  faChartColumn,
  faCheck,
  faChevronDown,
  faChevronLeft,
  faChevronRight,
  faChevronUp,
  faCircleCheck,
  faCog,
  faCopy,
  faCreditCard,
  faDollarSign,
  faDownload,
  faEllipsisVertical,
  faEllipsis,
  faExclamationCircle,
  faFileInvoice,
  faFileInvoiceDollar,
  faGear,
  faMagnifyingGlass,
  faPaperPlane,
  faPlus,
  faTriangleExclamation,
  faSpinner,
  faStore,
  faUser,
  faUsers,
  faXmark
);

const appName = import.meta.env.VITE_APP_NAME || 'Laravel';

import.meta.glob(['../fonts/**']);

createInertiaApp({
  title: (title) => `${appName} - ${title}`,
  resolve: async (name) => {
    const parts = name.split('::');

    if (parts.length > 1) {
      const modules = import.meta.glob(
        '../../modules/**/resources/js/**/*.tsx'
      );
      const modulePath: string[] = Object.keys(modules);
      const modulePages: { [key: string]: string } = {};
      modulePath.map((path: string): void => {
        const pathParts: string[] = path
          .replace('../../modules/', '')
          .split('/');
        const moduleName: string = pathParts.shift() as string;
        // @ts-ignore
        const pageName: string = pathParts
          .slice(2)
          .join('/')
          .replace('.tsx', '');
        const key: string = `${moduleName}::${pageName}`;
        modulePages[key] = path;
      });

      if (Object.keys(modulePages).includes(name)) {
        const path = modulePages[name];
        return await resolvePageComponent(
          path,
          import.meta.glob([
            './**/*.tsx',
            '../../modules/**/resources/js/**/*.tsx',
          ])
        );
      }
    }

    return await resolvePageComponent(
      `./${name}.tsx`,
      import.meta.glob(['./**/*.tsx', '../../modules/**/resources/js/**/*.tsx'])
    );
  },
  setup({ el, App, props }) {
    Sentry.init({
      dsn: import.meta.env.VITE_SENTRY_DSN_PUBLIC,
      integrations: [
        new Sentry.BrowserTracing({
          // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
          tracePropagationTargets: [
            'localhost',
            /^https:\/\/yourserver\.io\/api/,
          ],
        }),
        new Sentry.Replay({
          maskAllText: false,
          blockAllMedia: false,
        }),
      ],
      // Performance Monitoring
      tracesSampleRate: 1.0, //  Capture 100% of the transactions
      // Session Replay
      replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
      replaysOnErrorSampleRate: 1.0,
    });

    const root = createRoot(el);

    root.render(
      <React.StrictMode>
        <App {...props} />
        <ToastContainer
          position="top-right"
          autoClose={3000}
          hideProgressBar
          newestOnTop
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
        />
      </React.StrictMode>
    );
  },
  progress: {
    color: '#4B5563',
  },
});
